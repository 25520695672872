// Import necessary components from react-router-dom
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import './App.css';

// Import your page components
import HomePage from './pages/home';
import Test from './pages/test';
import GameOne from './pages/gameone';

function App() {
  return (
    <Router>
      <div className="App">
        {/* Define your routes within Routes component */}
        <Routes>
          <Route path="/" element={<HomePage />} /> 
          <Route path="/test" element={<Test />} />
          <Route path="/gameone" element={<GameOne/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
